export enum EventCampaignCategory {
  MOST_GUESTS = 'MOST_GUESTS',
  MOST_CREATIVE_CONCEPT = 'MOST_CREATIVE_CONCEPT',
  MOST_CONVINCING_APPLICATION = 'MOST_CONVINCING_APPLICATION',
  NORMAL_WG_PARTY = 'NORMAL_WG_PARTY',
  HALLOWEEN_WG_PARTY = 'HALLOWEEN_WG_PARTY',
  GAMING_STYLE_PARTY = 'GAMING_STYLE_PARTY',
  FIMO_CREATIVE_PARTY = 'FIMO_CREATIVE_PARTY',
  ONLY_PARTY_PACKAGE_PLS = 'ONLY_PARTY_PACKAGE_PLS',
}

export namespace EventCampaignCategory {

  export function getAllEventCampaignCategories(): EventCampaignCategory[] {
    return [
      EventCampaignCategory.NORMAL_WG_PARTY,
      EventCampaignCategory.HALLOWEEN_WG_PARTY,
      EventCampaignCategory.GAMING_STYLE_PARTY,
      EventCampaignCategory.FIMO_CREATIVE_PARTY,
      EventCampaignCategory.ONLY_PARTY_PACKAGE_PLS,
      EventCampaignCategory.MOST_GUESTS,
      EventCampaignCategory.MOST_CREATIVE_CONCEPT,
      EventCampaignCategory.MOST_CONVINCING_APPLICATION,
    ];
  }

  export function getDisplayName(category?: EventCampaignCategory): string {
    switch (category) {
      case EventCampaignCategory.MOST_GUESTS:
        return 'APP.CAMPAIGN.CATEGORY.MOST_GUESTS.TITLE';
      case EventCampaignCategory.MOST_CREATIVE_CONCEPT:
        return 'APP.CAMPAIGN.CATEGORY.MOST_CREATIVE_CONCEPT.TITLE';
      case EventCampaignCategory.MOST_CONVINCING_APPLICATION:
        return 'APP.CAMPAIGN.CATEGORY.MOST_CONVINCING_APPLICATION.TITLE';
      case EventCampaignCategory.NORMAL_WG_PARTY:
        return 'APP.CAMPAIGN.CATEGORY.WG.NORMAL_WG_PARTY.TITLE';
      case EventCampaignCategory.HALLOWEEN_WG_PARTY:
        return 'APP.CAMPAIGN.CATEGORY.WG.HALLOWEEN_WG_PARTY.TITLE';
      case EventCampaignCategory.GAMING_STYLE_PARTY:
        return 'APP.CAMPAIGN.CATEGORY.WG.GAMING_STYLE_PARTY.TITLE';
      case EventCampaignCategory.FIMO_CREATIVE_PARTY:
        return 'APP.CAMPAIGN.CATEGORY.WG.FIMO_CREATIVE_PARTY.TITLE';
      case EventCampaignCategory.ONLY_PARTY_PACKAGE_PLS:
        return 'APP.CAMPAIGN.CATEGORY.WG.ONLY_PARTY_PACKAGE_PLS.TITLE';
      default:
        return '';
    }
  }
}
